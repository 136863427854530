<!--
   ** limit: 上传限制数量
   ** keyList: Array 所有在列表中的key 数组
   ** mode: preview 显示 | edit 编辑
-->
<template>
  <div class="_upload">
    <div class="_upload-c">
      <div v-if="operate">
        <div v-for="(key, key_index) in keyList" :key="key_index" class="list preview">
          <div class="operate">
            <span @click="viewImg(key, key_index)"><i class="el-icon-zoom-in" /></span>
            <span v-if="mode === 'edit'" @click="deleteImg(key_index)">
              <i class="el-icon-delete" />
            </span>
          </div>
          <img :src="`${tinyimg_url}browse?fileCode=${key}`" alt="" @error="imgShowError($event, key_index)">
        </div>
      </div>
      <div v-else class="no-operate">
        <div v-for="(key, key_index) in keyList" :key="key_index" :class="['list preview no-operate-img', activeIndex === key_index ? 'activeImg' : '']">
          <img :src="`${tinyimg_url}browse?fileCode=${key}`" @click="imgDetail(key, key_index)" @error="imgShowError($event, key_index)">
        </div>
      </div>
      <div v-if="mode === 'edit' && keyList.length < limit" class="up-c">
        <div class="list">
          <label v-if="!percent" title="上传">
            <span class="icon-upload" />
            <span v-if="placeholder" class="up-placeholder">
              {{ placeholder }}
            </span>
            <input :accept="accept" class="upload-input" type="file" @change="uploadImg">
          </label>
          <el-progress v-if="percent" class="percent" type="circle" :width="50" :percentage="percent" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const upload_url = '/configure/file/upload';
const tinyimg_url = process.env.VUE_APP_MINIO_URL;
const highimg_url = process.env.VUE_APP_MINIO_URL;
// import pdf from "@/assets/icons/pdf.png";
import failImg from '@/assets/images/goodsOrder/img_fail_small.svg';
export default {
  name: 'Pic',
  props: {
    limit: {
      type: Number,
      default: 10,
    },
    keyList: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: 'edit',
    },
    accept: {
      type: String,
      default: 'image/png, image/jpeg, image/gif, application/pdf',
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '请上传',
    },
    operate: {
      type: Boolean,
      default: true,
    },
    imgTypeIndex: {
      type: Number,
      default: 0,
    },
    //	0-通用，1-证件类
    imgType: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      tinyimg_url,
      highimg_url,
      percent: 0,
      isFile: [false], // 当图片
      isImg: false,
      activeIndex: 0,
    };
  },
  computed: {
    fileReg() {
      const acceptItems = this.accept.split(',');
      const str = acceptItems
        .map((val) => {
          return val.trim().replace('/', '\\/');
        })
        .join('|');
      return new RegExp(`(${str})`);
    },
  },
  mounted() {
    this.curkeyList = JSON.parse(JSON.stringify(this.keyList));
  },
  methods: {
    ajax(formData) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('post', upload_url, true);
        xhr.upload.onprogress = (e) => {
          this.percent = Math.round((e.loaded / e.total) * 100);
        };
        xhr.upload.onloadstart = (e) => {
          console.log('开始上传');
          this.percent = 0;
        };
        xhr.onload = (e) => {
          console.log('上传完毕');
          this.percent = 0;
        };
        xhr.send(formData);
        xhr.onreadystatechange = function() {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              resolve(JSON.parse(xhr.responseText));
            } else {
              reject(`${xhr.status}:${xhr.statusText}`);
            }
          }
        };
      });
    },
    imgShowError(e, index) {
      e.target.height = 30;
      e.target.width = 30;
      e.target.src = failImg;
      this.isFile[index] = true;
    },
    uploadImg(e) {
      const files = e.target.files;
      const formData = new FormData();
      if (!this.fileReg.test(files[0].type)) {
        this.$baseMessage('上传的文件格式不正确，请重选符合规定的文件！', 'error');
        e.target.value = '';
        return;
      }
      if (/image/.test(files[0].type)) {
        this.isFile[this.keyList.length] = false;
        this.isImg = true;
      }
      formData.append('file', files[0]);
      formData.append('type', this.imgType);
      this.ajax(formData)
        .then((res) => {
          const keyList = this.keyList;
          // keyList.push(res.a);
          keyList.push(res.data);
          this.$emit('keyList:update', keyList);
          if (this.keyList.length === 1) {
            this.$emit('firstUpload', { keyList, imgTypeIndex: this.imgTypeIndex });
          }
          e.target.value = '';
        })
        .catch((err) => {
          console.log(err);
        });
    },
    viewImg(key, key_index) {
      if (this.isFile[key_index]) {
        window.open(`${highimg_url}browse?fileCode=${key}`, '文件预览');
      } else {
        this.$viewer({ urlList: [`${highimg_url}browse?fileCode=${key}`] });
      }
    },
    imgDetail(key, key_index) {
      this.activeIndex = key_index;
      this.$emit('imgDetail', { key, key_index, imgTypeIndex: this.imgTypeIndex });
    },
    deleteImg(index) {
      const keyList = this.keyList;
      keyList.splice(index, 1);
      this.isFile.splice(index, 1);
      this.$emit('keyList:update', keyList);
      this.percent = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
._upload {
  display: inline-block;
  ._upload-c {
    display: inline-block;
    vertical-align: top;
    .list {
      position: relative;
      display: inline-block;
      width: 120px;
      height: 90px;
      border: 1px solid #c0ccda;
      border-radius: 4px;
      margin: 0 10px 10px 0;
    }
    .percent {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .preview {
      position: relative;
      img {
        width: 100%;
        height: 100%;
        // object-fit: cover;
        object-fit: scale-down;
      }
      .operate {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;

        transition: opacity 0.3s;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          opacity: 1;
        }
        span {
          cursor: pointer;
          margin: 0 6px;
          font-size: 20px;
        }
      }
    }
    .no-operate-img {
      cursor: pointer;
    }
    .activeImg {
      border: 1px solid #1a6aff;
      border-radius: 2px;
    }
    .up-c {
      display: inline-block;
      vertical-align: top;
      label {
        display: inline-block;
        cursor: pointer;
        position: relative;
        width: 100%;
        height: 100%;
        .upload-input {
          display: none;
        }
        .icon-upload {
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          &::before {
            position: absolute;
            content: "";
            width: 30px;
            height: 2px;
            border-radius: 2px;
            background: #c0ccda;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          &::after {
            position: absolute;
            content: "";
            width: 30px;
            height: 2px;
            border-radius: 2px;
            background: #c0ccda;
            transform: translate(-50%, -50%) rotate(90deg);
            left: 50%;
            top: 50%;
          }
        }
      }
    }
  }
}
.no-pic {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 12px;
  i {
    font-size: 28px;
  }
}
.el-icon-files {
  position: absolute;
  font-size: 42px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: #666;
}
.up-placeholder {
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate(-50%, 0);
  line-height: 1.1;
  font-size: 12px;
  color: #c0ccda;
  text-align: center;
  width: 100%;
}
</style>
