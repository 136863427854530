<template>
  <!-- @close="close" -->
  <el-dialog
    :title="bandTitle"
    :visible="dialogVisible"
    width="80%"
    top="5vh"
    class="band-dialog"
    @close="close"
  >
    <div class="content-wrap">
      <div id="info" class="top-wrap">
        <div
          class="info"
        >
          <div>{{ bandInfo.carOrderNum || "--" }}</div>
          <div>
            <i class="iconfont icon-building-3-line" />
            {{ bandInfo.companyName||'-' }}
          </div>
          <div>
            <i class="iconfont icon-huowu" />
            {{ bandInfo.productName || "--" }}
            <span class="dun">{{ bandInfo.amount ||"--" }}吨</span>
          </div>
          <div>
            <i class="iconfont icon-xianlu" />
            <!-- {{ bandInfo.baseInfo.loadAddress || "--" }}-{{ bandInfo.baseInfo.unloadAddress || "--" }} -->
            {{ bandInfo.addressName }}
          </div>
          <div>
            <i class="iconfont icon-time-line" />
            {{ bandInfo.planTime | parseTime }}
          </div>
          <div>
            <i class="iconfont icon-driver-line" />
            驾
            <span>{{ bandInfo.driverName || "--" }}</span>
          </div>
          <div class="escort">
            <i class="iconfont icon-supercargo-line" />
            押
            <span>{{ bandInfo.supercargoName || "--" }}</span>
          </div>
          <div>
            <span
              class="car-num"
            ><span>{{ bandInfo.vehicleNum || "--" }}</span></span>
            <span
              class="car-num"
            ><span>{{ bandInfo.trailerNum || "--" }}</span></span>
          </div>
          <div class="escort">
            损耗
            <span class="weightSumcha">{{ weightSumCha }}</span>
            <span>吨</span>
          </div>
        </div>
      </div>
      <!-- :style="{ height: showAction ? 'calc(100% - 180px)' : 'calc(100% - 150px)' } -->
      <!-- v-loading="loading" -->
      <div
        :class="['middle-wrap flex',imgLimit>1?'has-sidebar':'no-sidebar']"
        :style="{ height: `calc(85vh - ${otherHeight}px)`}"
      >
        <!-- v-if="ruleForm.bandList.length"
          size="mini"-->
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          :rules="rules"
          label-position="left"
          label-width="70px"
          :hide-required-asterisk="true"
          class="form-wrap"
        >
          <div
            v-for="(item, index) in ruleForm.bandList"
            :key="index"
            class="all-info"
          >
            <div class="title">{{ item.typeName }}货信息</div>
            <div class="file-wrap flex">
              <div
                class="img-border flex-c-c"
                @mousemove="handleMouseover(index)"
                @mouseleave="handleMouseleave(index)"
              >
                <el-image
                  v-if="item.file.length"
                  style="width: 100%; height: 100%;"
                  :src="tinyimgUrl +'browse?fileCode='+ item.nowImg"
                  fit="scale-down"
                  class="flex-c-c"
                >
                  <div
                    slot="error"
                    class="flexc-c fail-img"
                  >
                    <img src="@/assets/images/goodsOrder/img_fail.svg">
                    <span>加载失败</span>
                  </div>
                </el-image>
                <div
                  v-else
                  class="flexc-c fail-img"
                >
                  <!-- <img src="@/assets/images/goodsOrder/img_nofile.svg"> -->
                  <div v-if="imgLimit>1">
                    <span>暂无文件</span>
                  </div>
                  <div v-else>
                    <span v-if="!showAction">暂无文件</span>
                  </div>
                  <el-form-item
                    v-if="imgLimit===1"
                    class="band-file btn-uploadfile"
                    label-width="0px"
                    :prop="'bandList.' + index + '.file'"
                    :rules="rules.file"
                  >
                    <imgLoad
                      :ref="`imgLoad${index}`"
                      :key-list="item.file"
                      :limit="imgLimit"
                      :mode="showAction ? 'edit' || 'preview' : 'preview'"
                      placeholder="上传照片"
                      accept="image/png, image/jpeg, image/jpg"
                      :operate="false"
                      :img-type-index="index"
                      class="img-load"
                      :btn-style="true"
                      @imgDetail="imgDetail"
                      @firstUpload="firstUpload"
                    />
                  </el-form-item>
                </div>
                <div
                  v-show="item.showImgAction &&item.file.length"
                  class="action-wrap flex-c"
                >
                  <i
                    class="iconfont icon-fangda"
                    @click="fangda(item, index)"
                  />
                  <div
                    v-if="showAction"
                    class="flex-c"
                  >
                    <span class="line" />
                    <el-upload
                      ref="input"
                      :action="fileUpload"
                      :on-success="
                        (res) => {
                          return onSuccess(res, index);
                        }"
                      :show-file-list="false"
                      :before-upload="beforeUpload"
                      :limit="1"
                      accept="image/png,image/jpg,image/jpeg,image/gif"
                    >
                      <i class="iconfont icon-shangchuan" />
                    </el-upload>
                    <span class="line" />
                    <i
                      class="iconfont icon-delete-bin-line"
                      @click="deleteImg(index)"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="imgLimit>1"
                class="img-list"
              >
                <el-form-item
                  class="band-file"
                  label-width="0px"
                  :prop="'bandList.' + index + '.file'"
                  :rules="rules.file"
                >
                  <imgLoad
                    :ref="`imgLoad${index}`"
                    :key-list="item.file"
                    :limit="imgLimit"
                    :mode="showAction ? 'edit' || 'preview' : 'preview'"
                    placeholder="上传照片"
                    accept="image/png, image/jpeg, image/jpg"
                    :operate="false"
                    :img-type-index="index"
                    class="img-load"
                    @imgDetail="imgDetail"
                    @firstUpload="firstUpload"
                  />
                </el-form-item>
              </div>
            </div>
            <div class="detail-wrap">
              <div>
                <el-row :gutter="40">
                  <!-- xs<768 sm>=768 md>=992 lg>=1200 xl>=1920 -->
                  <el-col
                    :md="14"
                    :lg="10"
                    :xl="9"
                  >
                    <el-form-item
                      :label="`${item.typeName}货吨数：`"
                      class="unit-wrap"
                      :prop="'bandList.' + index + '.weightSum'"
                      :rules="rules.weightSum"
                    >
                      <el-input
                        v-if="showAction"
                        v-model="item.weightSum"
                        size="small"
                        type="number"
                        placeholder="请输入"
                        @input="validateNumber('weightSum', 2, 3,index)"
                      />
                      <span
                        v-else
                        class="normal-value"
                      >{{ item.weightSum||'--' }}</span>
                      <span :class="showAction ? 'unit' : 'normal-unit'">吨</span>
                    </el-form-item>
                  </el-col>
                  <el-col
                    :md="8"
                    :lg="13"
                    :xl="10"
                  >
                    <el-form-item
                      :label="`${item.typeName}货时间：`"
                      :prop="'bandList.' + index + '.loadAndUnLoadTime'"
                      :rules="rules.loadAndUnLoadTime"
                      class="time-wrap"
                    >
                      <el-date-picker
                        v-if="showAction"
                        v-model="item.loadAndUnLoadTime"
                        type="datetime"
                        placeholder="请选择时间"
                        :value-format="valueFormat"
                        size="small"
                        @change="changeTime(index)"
                      />
                      <!-- v-else -->
                      <div v-else>{{ item.loadAndUnLoadTime | parseTime }}</div>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-form-item :label="`${item.typeName}货地址：`">{{ item.address || "--" }}</el-form-item>
                <!-- <el-form-item label="上传时间：">{{ item.createTime | parseTime }}</el-form-item> -->
              </div>
            </div>
            <!-- <div
              class="sign-wrap flex"
            >
              <div class="text-detail">
                <div class="flex">
                  <span class="label-wrap">打卡时间：</span>
                  <span class="value-wrap">{{ item.signtime | parseTime }}</span>
                </div>
                <div class="flex">
                  <span class="label-wrap">打卡地址：</span>
                  <span class="value-wrap">{{ item.signAddress || "--" }}</span>
                </div>
              </div>
              <div class="img-wrap">
                <d5c-upload
                  :key-list="item.signPhoto ? item.signPhoto.split(',').length === 1 ? [item.signPhoto] : item.signPhoto.split(',') : []"
                  :mode="'preview'"
                />

              </div>
            </div> -->
          </div>
          <div
            v-for="(item, index) in ruleForm.signInfoList"
            :key="index+'sign'"
            class="all-info"
          >
            <div
              class="sign-wrap flex"
            >
              <div class="text-detail">
                <!-- <div class="flex">
                <span class="label-wrap">上传时间：</span>
                <span class="value-wrap">{{ item.executeTime | parseTime }}</span>
              </div> -->
                <div class="flex">
                  <span class="label-wrap">打卡时间：</span>
                  <span class="value-wrap">{{ item.signtime | parseTime }}</span>
                </div>
                <div class="flex">
                  <span class="label-wrap">打卡地址：</span>
                  <span class="value-wrap">{{ item.signAddress || "--" }}</span>
                </div>
              </div>
              <!-- v-if="signInInfo[index]" -->
              <div class="img-wrap">
                <d5c-upload
                  :key-list="item.signPhoto ? item.signPhoto.split(',').length === 1 ? [item.signPhoto] : item.signPhoto.split(',') : []"
                  :mode="'preview'"
                />

              </div>
            </div>

          </div>
        </el-form>
        <!-- <div
          v-else
          class="no-data"
        >
          暂无数据
        </div> -->
      </div>
    </div>
    <div
      slot="footer"
    >
      <el-button @click="cancel">{{ cancelText }}</el-button>
      <el-button
        v-if="showAction&&ruleForm.bandList.length"
        type="primary"
        @click="onSave"
      >
        {{ saveText }}
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
// import { compress } from '@/utils/imgCompress';
import dayjs from 'dayjs';
import Big from 'big.js';
import imgLoad from '@/components/upload/imgload.vue';
import poundList from '@/api/pound';
// import { getCarReceiptsInfo, getCarReceiptsNew, getCarReceipts } from '@/api/band';
// import { upload } from '@/api/user';
export default {
  components: { imgLoad },
  filters: {
    parseTime: (time, cFormat = 'YYYY-MM-DD HH:mm:ss') => {
      if (!time || time === '--' || time === '-') return '--';
      return dayjs(time).format(cFormat);
    },
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    carOrderId: {
      type: [String, Number],
      default() {
        return 1;
      },
    },
    // 文件上传地址
    // eslint-disable-next-line vue/prop-name-casing
    fileUpload: {
      type: String,
      default() {
        return '/configure/file/upload';
      },
    },
    // 图片压缩预览地址
    tinyimgUrl: {
      type: String,
      default() {
        return process.env.VUE_APP_MINIO_URL;
      },
    },
    //  图片高清预览地址
    highimgUrl: {
      type: String,
      default() {
        return process.env.VUE_APP_MINIO_URL;
      },
    },
    // 磅单标题
    bandTitle: {
      type: String,
      default() {
        return '车单信息';
      },
    },
    // 磅单组件相关信息
    // bandInfo: {
    //   type: Object,
    //   default() {
    //     return {};
    //   },
    // },
    // 是否展示磅单底部操作按钮 取消/磅单确认
    showAction: {
      type: Boolean,
      default() {
        return true;
      },
    },
    // 上传图片限制数量 数量为1得时候为auv设计样式 其余兼容多张图片上传样式
    imgLimit: {
      type: Number,
      default() {
        return 1;
      },
    },
    // 取消按钮标题
    cancelText: {
      type: String,
      default() {
        return '取 消';
      },
    },
    // 确认按钮标题
    saveText: {
      type: String,
      default() {
        return '磅单核定';
      },
    },
    // 是否是千分位（值需要/1000）
    needThousands: {
      type: Boolean,
      default() {
        return true;
      },
    },
    // 时间格式
    valueFormat: {
      type: String,
      default() {
        return 'timestamp';
        // return 'yyyy-MM-dd HH:mm:ss'
      },
    },
    // 磅单列表接口所需参数
    receiptParams: {
      type: Object,
      default() {
        return {
          // carOrderId: null
          carOrderNum: '',
        };
      },
    },
    // 接口类型 new采用新接口 old老接口 默认new 特殊处理运单详情 才有old 老接口
    apiType: {
      type: String,
      default() {
        return 'new';
      },
    },
  },
  data() {
    return {
      typeDesc: {
        7: '装',
        9: '卸',
      },
      bandInfo: {
      },
      ruleForm: {
        bandList: [],
        signInfoList: [],
      },
      carAddressList: [],
      initDefaultData: [
        [{ type: 1, file: '', executeTime: '', address: '', createTime: '', id: '' }], // 无用 仅占位
        [{ type: 5, file: '', executeTime: '', address: '', createTime: '', id: '' }],
        [{ type: 7, weightSum: '', file: '', executeTime: '', address: '', createTime: '', id: '' }],
        [{ type: 8, file: '', executeTime: '', address: '', createTime: '', id: '' }],
        [{ type: 9, weightSum: '', file: '', executeTime: '', address: '', createTime: '', id: '' }],
      ],
      rules: {
        file: [{ required: true, validator: this.validCert, trigger: 'change' }],
        weightSum: [
          {
            required: true,
            validator: this.validateDun,
          },
        ],
        loadAndUnLoadTime: [{ required: true, message: '请选择时间' }],
      },
      typeIndex: 0,
      otherHeight: 180,
      loading: true,
    };
  },

  computed: {
    weightSumCha() {
      const weightSumArr = [];
      if (this.ruleForm.bandList) {
        this.ruleForm.bandList.forEach(d => {
          if (!/^[0-9]+.?[0-9]*$/.test(d.weightSum)) {
            weightSumArr.push('NaN');
          } else {
            if (+d.type === 7) {
              weightSumArr.push(d.weightSum);
            } else if (+d.type === 9) {
              weightSumArr.push(-(d.weightSum));
            }
          }
        });
        if (!weightSumArr.filter(d => d === 'NaN').length) {
          return weightSumArr.reduce((total, value) => parseFloat(new Big(total).plus(value)), 0);
        } else {
          return '--';
        }
      } else {
        return '--';
      }
    },
  },
  created() {
    this.getCarReceipts();
  },
  mounted() {
    this.$nextTick(() => {
      this.otherHeight = 132 + document.getElementById('info').clientHeight;
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.otherHeight = 132 + document.getElementById('info').clientHeight;
    },
    // 排序
    sortData(a, b) {
      return a.type - b.type;
    },
    filterArrayByType(array, type, defaultValue) {
      const filteredArray = array.filter(item => +item.nodeType === type);
      return filteredArray.length > 0 ? filteredArray : defaultValue || [];
    },
    // 获取磅单列表信息
    getCarReceipts() {
      poundList.getList({ id: this.carOrderId })
        .then(res => {
          this.bandInfo = {
            carOrderNum: res.carSerial || '-',
            companyName: res.customerName || '-',
            productName: res.goodsName || '-',
            amount: this.$parseNumber(res.goodsWeight, 1000),
            addressName: `${res.loadAddrName}-${res.unloadAddrName}`,
            planTime: res.createTime || '-',
            driverName: res.driverName || '-',
            supercargoName: res.escortName || '-',
            vehicleNum: res.truckNo || '-',
            trailerNum: res.guaNo || '-',
          };
          this.carAddressList = res.carAddressVOList || [];
          // 筛选7装 9卸
          const loadData = this.filterArrayByType(res.carNodeVOList, 7, this.initDefaultData[2]);
          const unloadData = this.filterArrayByType(res.carNodeVOList, 9, this.initDefaultData[4]);
          // 筛选5到装打卡 8到卸打卡
          const loadSignInfo = this.filterArrayByType(res.carNodeVOList, 5, this.initDefaultData[1]);
          const unloadSignInfo = this.filterArrayByType(res.carNodeVOList, 8, this.initDefaultData[3]);
          const allLoadUnloadData = loadData.concat(unloadData);
          const allLoadUnloadSignInfo = loadSignInfo.concat(unloadSignInfo);
          const newBandList = [];
          allLoadUnloadData.forEach(e => {
            e.typeName = this.typeDesc[e.nodeType || e.type];
            // e.executeTime = e.executeTime || '';
            // e.signtime = e.signtime || '';
            if (!/^[0-9]+.?[0-9]*$/.test(e.ext1)) {
              e.ext1 = '';
            } else {
              if (this.needThousands) {
                e.ext1 = parseFloat(new Big(+e.ext1).div(1000));
              } else {
                e.ext1 = +e.ext1;
              }
            }
            const obj = {
              typeName: e.typeName,
              weightSum: e.ext1,
              file: (e.files && e.files.split(',')) || [],
              // file: ["JDBC15645E0147BFD15,,,", "J6191C3A490159ABF61"],
              isEdit: false,
              // id: e.id,parseTime
              loadAndUnLoadTime: e.nodeTime === '--' ? '' : e.nodeTime,
              // index: e.index,
              // sort: e.sort,
              type: e.nodeType || e.type,
              address: e.nodeAddr,
              nowImg: (e.files && e.files.split(',')[0]) || '',
              nowImgIndex: 0,
              showImgAction: false,
              // executeTime: e.executeTime,
              createTime: e.nodeTime === '--' ? '' : e.nodeTime,
              // signtime: e.signtime,
              // signAddress: e.signAddress,
              // signPhoto: e.signPhoto,
              id: e.cId,
              weightSumEdit: false, // 磅单是否修改过(图片 数量 日期) 方便后期调用修改接口识别哪些磅单需要调接口 没修改到的不需要调接
              btnType: e.files ? 2 : 1, // 1是新增 2是编辑
              cAddrId: e.cAddrId || '',
            };
            newBandList.push(obj);
          });

          // 赋值装卸货磅单列表
          this.ruleForm.bandList = newBandList || [];
          const newSignInfoList = [];
          allLoadUnloadSignInfo.forEach(e => {
            // e.signtime = e.executeTime || '';
            const obj = {
              signtime: e.nodeTime,
              signAddress: e.nodeAddr,
              signPhoto: e.files,
              id: e.cId,
            };
            newSignInfoList.push(obj);
          });
          this.ruleForm.signInfoList = newSignInfoList || [];
        });
    },
    validateNumber(val, integer, decimals, i) {
      // integer 整数位限制几位  decimals小数位限制几位
      // 正则限制输入的金额不能为中文、特殊字符、两位小数
      const reg = /^[0-9]+.?[0-9]*/;
      // 如果不符合正则，提示
      if (!reg.test(+this.ruleForm.bandList[i][val])) {
        this.ruleForm.bandList[i][val] = '';
      }
      // 保留两位小数
      const price = this.ruleForm.bandList[i][val];
      const pArr = price.split('.');
      pArr[0] = pArr[0].substr(0, integer);
      if (pArr.length > 1) {
        this.ruleForm.bandList[i][val] = pArr[0] + '.' + pArr[1].substr(0, decimals);
      } else {
        this.ruleForm.bandList[i][val] = pArr[0];
      }
      this.ruleForm.bandList[i].weightSumEdit = true;
    },
    // 修改时间
    changeTime(i) {
      this.ruleForm.bandList[i].weightSumEdit = true;
    },
    // 鼠标划入
    handleMouseover(index) {
      if (this.ruleForm.bandList[index].showImgAction) {
        return;
      }
      this.ruleForm.bandList[index].showImgAction = true;
    },
    // 鼠标离开
    handleMouseleave(index) {
      this.ruleForm.bandList[index].showImgAction = false;
    },
    // 右侧图片列表点击某一图片返回相关图片信息
    imgDetail({ key, key_index, imgTypeIndex }) {
      this.typeIndex = imgTypeIndex;
      this.ruleForm.bandList[imgTypeIndex].nowImgIndex = key_index;
      this.ruleForm.bandList[imgTypeIndex].nowImg = key;
    },
    // 初次上传图片
    firstUpload({ keyList, imgTypeIndex }) {
      this.ruleForm.bandList[imgTypeIndex].nowImg = keyList[0];
      this.ruleForm.bandList[imgTypeIndex].weightSumEdit = true;
    },
    fangda(data, index) {
      const showImg = data.file.map((d, i) => ({
        d: this.highimgUrl + 'browse?fileCode=' + d,
        index: i,
        // value: d
      }));
      const a = showImg.slice(0, data.nowImgIndex);
      const b = showImg.slice(data.nowImgIndex);
      const ab = b.concat(a);
      this.$viewer({
        urlList: ab.map(d => d.d),
        // 切换联动暂时隐藏不需要
        // onSwitch: val => {
        //   if (b[0]) {
        //     this.ruleForm.bandList[index].nowImgIndex = ab[val].index
        //     this.ruleForm.bandList[index].nowImg = ab[val].value
        //     this.$refs[`imgLoad${index}`][0].activeIndex = ab[val].index
        //   }
        // }
      });
    },
    // 压缩超过1M得图片
    // handlePictureCardPreview(file) {
    // return new Promise(resolve => compress(file, val => {
    //   resolve(val);
    // }));
    // },
    beforeUpload(file) {
      const acceptItems = 'image/png,image/jpg,image/jpeg,image/gif'.split(',');
      const isJPG = acceptItems.includes(file.type);
      // const isLt1M = file.size / 1024 / 1024 > 1;
      if (!isJPG) {
        this.$message.error('上传的文件格式不正确，请重选符合规定的文件！');
        return false;
      }
      const isLt10M = file.size / 1024 / 1024 > 10;
      if (isLt10M) {
        this.$message.error('图片大小超过10M，请重新上传！');
        return false;
      }
      // if (isLt1M) {
      //   return this.handlePictureCardPreview(file);
      // }
    },
    onSuccess(fileList, index) {
      this.$refs.input[index].clearFiles(); // 上传成功之后清除历史记录
      if (fileList.data) {
        this.$set(this.ruleForm.bandList[index].file, this.ruleForm.bandList[index].nowImgIndex, fileList.data);
        this.ruleForm.bandList[index].nowImg = fileList.data;
        this.ruleForm.bandList[index].weightSumEdit = true;
      }
    },
    deleteImg(index) {
      this.ruleForm.bandList[index].file.splice(this.ruleForm.bandList[index].nowImgIndex, 1);
      if (this.ruleForm.bandList[index].nowImgIndex > 0) {
        // this.$refs[`imgLoad${index}`][0].activeIndex = this.ruleForm.bandList[index].nowImgIndex - 1
        this.ruleForm.bandList[index].nowImg = this.ruleForm.bandList[index].file[this.ruleForm.bandList[index].nowImgIndex - 1];
      } else {
        // this.$refs[`imgLoad${index}`][0].activeIndex = 0
        this.ruleForm.bandList[index].nowImg = this.ruleForm.bandList[index].file[this.ruleForm.bandList[index].nowImgIndex];
      }
      this.ruleForm.bandList[index].nowImgIndex = this.ruleForm.bandList[index].nowImgIndex ? this.ruleForm.bandList[index].nowImgIndex - 1 : 0;
      this.ruleForm.bandList[index].weightSumEdit = true;
    },
    // 验证凭证
    validCert(rule, value, callback) {
      if (!value || !(value instanceof Array) || !value.length) {
        return callback(new Error('图片不能为空'));
      }
      callback();
    },
    // 验证磅单
    validateDun(rule, value, callback) {
      const reg = /^(0|[1-9]\d*)(\s|$|\.\d{1,3}\b)/;
      const valueString = value.toString();
      const index = valueString.indexOf('.');
      let flag = false;
      const integer = valueString.slice(0, index);
      if (index >= 0 && integer.length > 6) {
        flag = true;
      } else if (index < 0 && valueString.length > 6) {
        flag = true;
      }
      if ((rule.required && !value) || (value && !reg.test(value)) || parseFloat(value) > 10000000 || flag) {
        callback(new Error('请输入合理的吨数'));
      } else {
        callback();
      }
    },
    cancel() {
      this.close();
    },
    onSave() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const addressTypeMap = {
            7: 1,
            9: 2,
          };
          const hasEditList = this.ruleForm.bandList.map(d => {
            return {
              id: d.cAddrId,
              cId: d.id,
              receiptNum: this.needThousands ? parseFloat(new Big(+d.weightSum).times(1000)) : +d.weightSum,
              receiptFiles: d.file.join(',') || '',
              receiptTime: d.loadAndUnLoadTime,
              type: addressTypeMap[+d.type],
            };
          });
          poundList.updatePoundBill(hasEditList)
            .then((res) => {
              if (res) {
                this.$message.success('操作成功');
                this.$emit('savePound', this.ruleForm.bandList);
                this.close();
              }
            });
        } else {
          this.$message.error('请填写完整');
          return false;
        }
      });
    },
    close() {
      this.$emit('update:dialogVisible', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.flex {
  display: flex;
}
.flex-c {
  display: flex;
  align-items: center;
}
.flexc-c {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.flex-c-c {
  display: flex;
  justify-content: center;
  align-items: center;
}
.band-dialog {
  // height: 100vh;
  .title {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #26272a;
  }
  ::v-deep{
    .el-dialog__footer{
      text-align: center;
    }
  }
  .content-wrap {
    height: 100%;
    margin-top: 0;
    color: #26272a;
    .top-wrap {
      .info {
        display: flex;
        padding: 10px 16px;
        background: #f3f4f7;
        border-radius: 4px;
        flex-wrap: wrap;
        > div {
          display: flex;
          height: 28px;
          line-height: 28px;
          align-items: center;
          &::after {
            display: inline-block;
            width: 1px;
            height: 16px;
            margin: 0 16px;
            background: #bcbdc0;
            content: "";
          }
          > i {
            margin-right: 3px;
          }
          > span {
            margin-left: 8px;
          }
          .dun {
            margin-left: 12px;
          }
          .weightSumcha {
            font-size: 16px;
            font-weight: 600;
          }
        }
        .escort {
          margin-right: 16px;
          &::after {
            display: none;
            content: "";
          }
        }
        .car-num {
          margin-right: 4px;
          font-size: 12px;
          background: #fcb910;
          border-radius: 1px;
          span {
            display: flex;
            height: 24px;
            padding: 3px 8px;
            margin: 2px;
            line-height: 14px;
            color: #26272a;
            border: 1px solid #26272a;
            align-items: center;
          }
        }
      }
    }
    .middle-wrap {
      display: flex;
      height: calc(100vh - 260px);
      margin-top: 20px;
      overflow-x: hidden;
      overflow-y: scroll;
      flex-wrap: wrap;
      .form-wrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .all-info {
          width: 50%;
          .file-wrap {
            margin-bottom: 16px;
            .img-border {
              position: relative;
              width: 100%;
              // height: 43.778vh;
              // margin-right: 16px;
              margin-right: 8px;
              background: #fff;
              border: 1px solid #dbdce0;
              border-radius: 4px;
              .fail-img {
                span {
                  margin-top: 15px;
                  color: #8a8b8e;
                }
              }
              .action-wrap {
                position: absolute;
                bottom: 30px;
                // width: 150px;
                height: 60px;
                padding: 20px;
                background: rgba(0, 0, 0, 0.5);
                // background-color: #606266;
                border-radius: 4px;
                i {
                  font-size: 20px;
                  // color: rgba(255, 255, 255, 0.7);
                  color: #fff;
                  cursor: pointer;
                  opacity: 0.7;
                  &:hover {
                    // color: rgba(255, 255, 255, 1);
                    opacity: 1;
                  }
                }
                .line {
                  display: inline-block;
                  width: 1px;
                  height: 16px;
                  margin: 0 12px;
                  background: #fff;
                  opacity: 0.4;
                }
                ::v-deep{
                  .el-upload{
                    background: transparent;
                    border:0
                  }
                }
              }
            }
            .img-list {
              width: 60px;
              ::v-deep {
                ._upload {
                  .list,
                  .preview {
                    width: 60px;
                    height: 60px;
                  }
                }
                ._upload,
                ._upload-c,
                .list {
                  margin-right: 0 !important;
                }
              }
            }
            .btn-uploadfile {
              margin-top: 15px;
            }
          }
          .detail-wrap {
            .loss {
              height: 36px;
              line-height: 36px;
            }
            .normal-value {
              margin-right: 4px;
              font-size: 16px;
              font-weight: 600;
              color: #26272a;
            }
          }
          .sign-wrap {
            margin: 16px 0;
            .text-detail {
              // max-width: calc(100% - 196px);
              margin-right: 16px;
              > div {
                height: 28px;
                line-height: 28px;
                .label-wrap {
                  width: 70px;
                }
                .value-wrap {
                  flex: 1;
                  flex-wrap: wrap;
                }
              }
            }
            .img-wrap {
              ::v-deep {
                ._upload {
                  .list,
                  .preview {
                    width: 60px;
                    height: 60px;
                  }
                  // img {
                  //   width: 60px;
                  //   height: 60px;
                  // }
                }
                ._upload-c {
                  > div {
                    &:last-child {
                      margin-right: 0;
                    }
                  }
                }
              }
            }
          }
          &:nth-child(odd) {
            padding-right: 20px;
          }
          &:nth-child(even) {
            padding-left: 20px;
          }
        }
      }
      .no-data{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .has-sidebar {
      .all-info {
        &:nth-child(odd) {
          padding-right: 20px;
        }
        &:nth-child(even) {
          padding-left: 20px;
        }
      }
      .img-border {
        // height:  43.778vh;
        height: 394px;
      }
    }
    .no-sidebar {
      padding: 0 35px;
      .all-info {
        &:nth-child(odd) {
          padding-right: 45px;
        }
        &:nth-child(even) {
          padding-left: 45px;
        }
      }
      .img-border {
        // height: 42.089vh;
        height: 394px;
      }
    }
    .action-wrap {
      padding-top: 12px;
      text-align: center;
    }
  }
}
::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(144, 147, 153, 0.3);
  border: 3px solid transparent;
  border-radius: 7px;
  background-clip: padding-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(144, 147, 153, 0.5);
}
::-webkit-scrollbar-track {
  background-color: white;
}
::-webkit-scrollbar-track:hover {
  // background-color: #f8fafc;
  background-color: white;
}
::v-deep {
  .band-file{
    .el-form-item__error {
      padding-top: 0;
      margin-top: 25px;
      margin-left: 20px;
    }
  }
  .time-wrap{
    .el-form-item__error {
      padding-top: 0;
      margin-top: 0px;
    }
  }
  .el-form {
    padding-right: 0;
  }
  .unit-wrap {
    .el-form-item__content {
      display: flex;
      .el-input__inner {
        padding-right: 25px;
        font-size: 16px;
        font-weight: 600;
        color: #26272a;
        &::placeholder {
          // text-align: left;
          font-size: 14px;
          font-weight: normal;
        }
      }
      .el-form-item__error {
        padding-top: 0;
        margin-top: -1px;
      }
      .el-input__suffix {
        padding-right: 15px;
      }
      .unit {
        z-index: 1;
        // margin-top: 2px;
        margin-left: -25px;
      }
    }
  }
  // .el-form-item {
  //   margin-bottom: 3px;
  //   .el-form-item__label {
  //     padding-right: 0;
  //     color: #58595c;
  //   }
  // }
  .el-form-item{
    height: 36px;
    margin-bottom: 5px;
    .el-form-item__label {
      padding-right: 0;
      color: #58595c;
    }
    .el-form-item__content{
      display: flex;
      height: 36px;
      align-items: center;
    }
  }
  .el-button {
    height: 38px;
  }
  .el-image {
    .flexc-c {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  input::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

@media only screen and (max-width: 1200px) {
  .el-col-md-8 {
    width: 340px;
  }
  .el-col-md-14 {
    width: 330px;
    margin-bottom: 6px;
  }
}
</style>
